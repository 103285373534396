import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import SnippetContainer from './components/SnippetContainer';
import SnippetForm from './components/SnippetForm';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			customize: false,
			formData: {},
			repo: 'Raven',
		}
	}

	handleRepoChange = (e) => {
		if (e.target.value === this.state.repo) {
			return;
		}

		this.setState({
			formData: {},
			repo: e.target.value
		});
	}

	handleValueChange = (e) => {
		console.debug(e.target.name, e.target.value, e.target.checked, e.target.type);
		let name = e.target.name;
		let value;

		switch (e.target.type) {
			case 'number':
			case 'text':
				value = e.target.value;
				console.log(value)
				break;
			case 'checkbox':
				value = e.target.checked;
				break;
			default:
				console.error('Invalid type: ', e.target.type);
				return;
		}

		let formData = this.state.formData;
		formData[name] = value;

		this.setState({
			formData: formData
		});
	}

	generateForm = () => {
		let fields = [];

		switch (this.state.repo) {
			case 'Raven':
				fields.push({
					type: 'number',
					name: 'displayID',
					friendlyName: 'Display ID'
				});
				break;
			case 'SootySwift':
				fields.push(
					{
						type: 'number',
						name: 'highTemp',
						friendlyName: 'High Temperature'
					},
					{
						type: 'number',
						name: 'maxTemp',
						friendlyName: 'Maximum Temperature'
					},
					{
						type: 'number',
						name: 'minTemp',
						friendlyName: 'Minimum Temperature'
					},
					{
						type: 'checkbox',
						name: 'tempShowGood',
						friendlyName: 'Show Good Temperature'
					},
					{
						type: 'checkbox',
						name: 'tempShowHigh',
						friendlyName: 'Show High Temperature'
					},
					{
						type: 'number',
						name: 'slideTransitionTime',
						friendlyName: 'Slide Transition Time (in seconds)'
					},
					{
						type: 'number',
						name: 'timerTemperatureNormal',
						friendlyName: 'Timer Temperature Normal'
					},
					{
						type: 'number',
						name: 'timerTemperatureHigh',
						friendlyName: 'Timer Temperature High'
					},
					{
						type: 'number',
						name: 'timerTemperatureError',
						friendlyName: 'Timer Temperature Error'
					}
				);
				break;
			case 'DuskySwift':
				fields.push(
					{   // Device ID format is 12345-1
						type: 'text',
						name: 'deviceID',
						friendlyName: 'Cleared4Work Device ID'
					},
					{
						type: 'number',
						name: 'highTemp',
						friendlyName: 'High Temperature'
					},
					{
						type: 'number',
						name: 'maxTemp',
						friendlyName: 'Maximum Temperature'
					},
					{
						type: 'number',
						name: 'minTemp',
						friendlyName: 'Minimum Temperature'
					},
					{
						type: 'checkbox',
						name: 'tempShowGood',
						friendlyName: 'Show Good Temperature'
					},
					{
						type: 'checkbox',
						name: 'tempShowHigh',
						friendlyName: 'Show High Temperature'
					},
					{
						type: 'number',
						name: 'slideTransitionTime',
						friendlyName: 'Slide Transition Time (in seconds)'
					},
					{
						type: 'number',
						name: 'timerTemperatureError',
						friendlyName: 'Timer Temperature Error'
					},
					{
						type: 'number',
						name: 'timerQrScanInstructions',
						friendlyName: 'Timer QR Scan Instructions'
					},
					{
						type: 'number',
						name: 'timerQrScanInvalidUrl',
						friendlyName: 'Timer QR Scan Invalid URL'
					},
					{
						type: 'number',
						name: 'timerQrScanGoodUrl',
						friendlyName: 'Timer QR Scan Good URL'
					}
				);
				break;
			default:
				console.error('invalid repo: ', this.state.repo);
				return;
		}

		return (
			<SnippetForm
				fields={fields}
				formData={this.state.formData}
				onChange={this.handleValueChange}
			/>
		);
	}

	render() {
		let form = this.generateForm();

		return (
			<div>
				<header className="App-header">
					<h1 className="App-title">Legacy Config Tool</h1>
				</header>
				<Container fluid>
					<Row className="justify-content-md-center m-3">
						<Col md={1}>
							<Button
								className='repo-btn'
								variant={this.state.repo === 'Raven' ? 'success' : 'info'}
								onClick={this.handleRepoChange}
								value='Raven'
							>
								Raven
						</Button>
						</Col>
						<Col md={1}>
							<Button
								className='repo-btn'
								variant={this.state.repo === 'SootySwift' ? 'success' : 'info'}
								onClick={this.handleRepoChange}
								value='SootySwift'
							>
								Taos
						</Button>
						</Col>
						<Col md={1}>
							<Button
								className='repo-btn'
								variant={this.state.repo === 'DuskySwift' ? 'success' : 'info'}
								onClick={this.handleRepoChange}
								value='DuskySwift'
							>
								Taos with Cleared4Work
						</Button>
						</Col>
					</Row>
					<Row>
						<Col xs={3}>
							{form}
						</Col>
						<Col xs={9}>
							<SnippetContainer
								customize={this.state.customize}
								formData={this.state.formData}
								repo={this.state.repo}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		);
	};
}

export default App;
