import React, { Component } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import '../App.css';

class SnippetContainer extends Component {
	generateSnippet = (repo) => {
		let slideTransitionTime = 8000;
		if (this.props.formData.slideTransitionTime) {
			slideTransitionTime = this.props.formData.slideTransitionTime * 1000;
		}

		let snippet;

		switch (repo) {
			case 'Raven':
				snippet = `<!-- START LEGACY RAVEN --- WARNING: Must have <div id="ravenEventContainer"></div> in the template for this to work! -->
<script type='text/javascript' src='./hamerkop.js'></script>
<script defer type='text/javascript'>
\twindow.LEGACY_RAVEN_SERVER = 'legacy-raven-test.touchsource.com';
\twindow.LEGACY_RAVEN_PROXY_SERVER = 'localhost:3333';
\tfunction initRaven() {
\t\tconst Raven = require('/raven');
\t\tvar raven = new Raven(${this.props.formData.displayID});
\n\t\traven.getBroncoSettings();
\n\t\traven.getParrotConfig(raven.parrotOpts).then((contents) => {
\t\t\tlet configList = [contents];
\t\t\tconsole.log(contents);
\t\t\traven.startParrot(configList);
\t\t}).catch((err) => {
\t\t\tconsole.error(\`Cannot retrieve Parrot Config, halting error: \${err}\`);
\t\t});
\t}
\n\tvar proxyFetched = false;
\tfunction fetchRaven() {
\t\tif (typeof Raven === 'undefined') {
\t\t\tlet p = window.location.protocol;
\t\t\tlet ravenScript = \`$\{p\}//$\{window.LEGACY_RAVEN_SERVER\}/raven/raven.js\`
\t\t\tif (window.LEGACY_RAVEN_PROXY_SERVER) {
\t\t\t\travenScript = \`$\{p\}//$\{window.LEGACY_RAVEN_PROXY_SERVER\}/?url=$\{encodeURIComponent(p + '//' + window.LEGACY_RAVEN_SERVER + '/raven/raven.js')\}&update=1\`
\t\t\t}
\t\t\tconsole.info(ravenScript);\n
\t\t\tlet script = document.createElement('script');
\t\t\tscript.type = 'text/javascript';
\t\t\tscript.src = ravenScript;
\t\t\tscript.onload = initRaven;
\t\t\tscript.onerror = () => {
\t\t\t\tif (!proxyFetched) {
\t\t\t\t\tconsole.warn("WARN: raven.js must be loaded before use...");
\t\t\t\t\tproxyFetched = true;
\t\t\t\t}
\t\t\t\tsetTimeout(() => {
\t\t\t\t\tconsole.error('Raven failed to fetch: ' + ravenScript);
\t\t\t\t\tfetchRaven();
\t\t\t\t}, 5000);
\t\t\t\treturn;
\t\t\t};
\t\t\tdocument.body.appendChild(script);
\t\t} else {
\t\tinitRaven();
\t\t}
\t};
\twindow.addEventListener('load', fetchRaven);
</script>
<!-- END LEGACY RAVEN -->`
				break;
			case 'SootySwift':
				snippet = `<!-- START SOOTYSWIFT 2.1.0 -->
<!-- needs to be loaded once in house before going to client, standard offline -->
<link rel="stylesheet" type="text/css" href="http://localhost:3333/?url=https%3A%2F%2Fswift.touchsource.com%2Fswift%2FsootySwift-2.0.0.css&update=1">
<div id="sootySwift" class="sootySwift hiddenSooty"></div>
<script type="text/javascript" src="http://localhost:3333/?url=https://swift.touchsource.com/swift/sootySwift-2.1.0.js&update=1"></script>
<script type="text/javascript">
\tlet sootySwift = new SootySwift({
\t\thighTemp:               ${this.props.formData.highTemp || 100.4},
\t\tmaxTemp:                ${this.props.formData.maxTemp || 115},
\t\tminTemp:                ${this.props.formData.minTemp || 95},
\t\tslideTransitionTime:    ${slideTransitionTime},
\t\ttempShowGood:           ${this.props.formData.tempShowGood || false},
\t\ttempShowHigh:           ${this.props.formData.tempShowHigh || false},
\t\ttimerTemperatureNormal: ${this.props.formData.timerTemperatureNormal || 3},
\t\ttimerTemperatureHigh:   ${this.props.formData.timerTemperatureHigh || 5},
\t\ttimerTemperatureError:  ${this.props.formData.timerTemperatureError || 6},
\t});
\tsootySwift.init();
</script>
<!-- END SOOTYSWIFT -->`                
				break;
			case 'DuskySwift':
				snippet = `<!-- START DUSKYSWIFT 2.1.0 -->
<!-- needs to be loaded once in house before going to client, standard offline -->
<link rel="stylesheet" type="text/css" href="http://localhost:3333/?url=https%3A%2F%2Fswift.touchsource.com%2Fswift%2FsootySwift-2.0.0.css&update=1">
<div id="sootySwift" class="sootySwift hiddenSooty"></div>
<script type="text/javascript" src="http://localhost:3333/?url=https://swift.touchsource.com/swift/sootySwift-2.1.0.js&update=1"></script>
<script type='text/javascript'>
\tlet duskySwift = new SootySwift({
\t\tdeviceID: '${this.props.formData.deviceID || ''}',
\t\thighTemp:                ${this.props.formData.highTemp || 100.4},
\t\tmaxTemp:                 ${this.props.formData.maxTemp || 115},
\t\tminTemp:                 ${this.props.formData.minTemp || 95},
\t\tslideTransitionTime:     ${slideTransitionTime},
\t\ttempShowGood:            ${this.props.formData.tempShowGood || false},
\t\ttempShowHigh:            ${this.props.formData.tempShowHigh || false},
\t\ttimerTemperatureError:   ${this.props.formData.timerTemperatureError || 6},
\t\ttimerQrScanInstructions: ${this.props.formData.timerQrScanInstructions || 8},
\t\ttimerQrScanInvalidUrl:   ${this.props.formData.timerQrScanInvalidUrl || 10},
\t\ttimerQrScanGoodUrl:      ${this.props.formData.timerQrScanGoodUrl || 7}
\t});
\tduskySwift.init();
</script>
<!-- END DUSKYSWIFT -->`				
				break;
			default:
				console.error('invalid repo: ', repo);
		}

		if (this.props.customize) {
			snippet += `
\t\t<div>
\t\t</div>`
		}

		return snippet
	}

	render() {
		let repo = this.props.repo;
		let formData = this.props.formData;

		if (repo === 'Raven' && formData && (!formData.displayID || formData.displayID === '')) {
			return (
				<SyntaxHighlighter className='snippet' language='javascript' style={monokai}>
					Please enter a display ID...
				</SyntaxHighlighter >
			)
		}

		if (repo === 'DuskySwift' && formData && (!formData.deviceID || formData.deviceID === '')) {
			return (
				<SyntaxHighlighter className='snippet' language='javascript' style={monokai}>
					Please enter a device ID...
				</SyntaxHighlighter >
			)
		}

		let snippet = this.generateSnippet(repo);

		return (
			<SyntaxHighlighter className='snippet' language='javascript|html' style={monokai}>
				{snippet}
			</SyntaxHighlighter >
		)
	}
}

export default SnippetContainer;