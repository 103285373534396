import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';

class SnippetForm extends Component {

	generateFields = () => {
		let inputFields = this.props.fields.map((field, i) => {
			let jsxField;
			switch (field.type) {
				case 'number':
				case 'text':
					jsxField = <Form.Control name={field.name} type={field.type} value={this.props.formData[field.name] || ''} onChange={this.props.onChange} />
					break;
				case 'checkbox':
					jsxField = <Form.Check name={field.name} type={field.type} checked={this.props.formData[field.name] || false} onChange={this.props.onChange} />
					break;
				default:
					console.error('invalid field type: ', field.type);
			}
			
			return (
				<Form.Group controlId={`${field.name}_${i}`} key={`${field.name}_${i}`}>
					<Form.Label>{field.friendlyName}</Form.Label>
					{jsxField}
				</Form.Group>
			);
		});

		return inputFields;
	}

	render() {
		let fields = this.generateFields();

		return (
			<Form>
				{fields}
			</Form>
		);
	}
}

export default SnippetForm;